import React, { useEffect, useState } from 'react';
import API from '../api';
import { Link } from 'react-router-dom';
import { Table, Button, Typography, Spin } from 'antd';

interface User {
  id: number;
  name: string;
  email: string;
  logMessages: Array<{ id: number; logMessage: string; timestamp: string }>;
}

const UsersList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    API.get('/user/get-users')
      .then((response) => {
        setUsers(response.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = (userId: number) => {
    API.delete(`/user/delete-user/${userId}`)
      .then(() => setUsers(users.filter((user) => user.id !== userId)))
      .catch(console.error);
  };

  const columns = [
    { title: 'Ad', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Durum',
      key: 'status',
      render: (_, user: User) => {
        const now = new Date();
        const isActive = user.logMessages.some((log) => {
          const logTime = new Date(log.timestamp);
          return (now.getTime() - logTime.getTime()) / 1000 <= 60; 
        });
        return <Typography.Text type={isActive ? 'success' : 'danger'}>{isActive ? 'Aktif' : 'Pasif'}</Typography.Text>;
      },
    },
    {
      title: 'Detaylar',
      key: 'details',
      render: (_, user: User) => (
        <Link to={`/user/${user.id}`}>
          <Button type="primary">Detaylar</Button>
        </Link>
      ),
    },
    {
      title: 'Sil',
      key: 'delete',
      render: (_, user: User) => (
        <Button type="primary" danger onClick={() => handleDelete(user.id)}>
          Sil
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Typography.Title level={3}>Kullanıcı Listesi</Typography.Title>
      {loading ? (
        <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />
      ) : (
        <Table dataSource={users} columns={columns} rowKey="id" pagination={{ pageSize: 5 }} />
      )}
    </div>
  );
};

export default UsersList;
