import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UsersList from './components/UsersList';
import UserDetails from './components/UserDetails';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import UserPage from './components/UserPage';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<PrivateRoute element={<UsersList />} isAuthenticated={isAuthenticated} />} 
        />
        <Route 
          path="/user/:id" 
          element={<PrivateRoute element={<UserDetails />} isAuthenticated={isAuthenticated} />} 
        />
        <Route 
          path="/userPage/:id" 
          element={<PrivateRoute element={<UserPage />} isAuthenticated={isAuthenticated} />} 
        />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
};

export default App;
