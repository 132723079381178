import React, { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Typography, message, Spin, Input, Button, Tabs, BadgeProps } from 'antd';
import { useParams } from 'react-router-dom'; 
import API from '../api';
import { Area } from '@ant-design/plots';
import { Calendar } from 'antd';
import type { Dayjs } from 'dayjs';
import { Badge } from 'antd';
import { useNavigate } from 'react-router-dom';  

const { Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

interface User {
  id: number;
  name: string;
  email: string;
}

interface Log {
  id: number;
  logMessage: string;
  timestamp: string;
}

interface LinkedInUser {
  id: number;
  pageUrl: string;
  timestamp: string;
  follow: boolean;
  connect: boolean;
  linkedinUserName: string;
  linkedinUserWork: string;
  linkedinUserLocation: string;
  linkedinUserConnection: number;
  linkedinUserInfo: string;
}

interface Message {
  sender: string;
  content: string;
  timestamp: string;
}

const getListData = (value: Dayjs) => {
  let listData: { type: string; content: string }[] = [];
  switch (value.date()) {
    case 8:
      listData = [
        { type: 'warning', content: 'This is warning event.' },
        { type: 'success', content: 'This is usual event.' },
      ];
      break;
    case 10:
      listData = [
        { type: 'warning', content: 'This is warning event.' },
        { type: 'success', content: 'This is usual event.' },
        { type: 'error', content: 'This is error event.' },
      ];
      break;
    case 15:
      listData = [
        { type: 'warning', content: 'This is warning event' },
        { type: 'success', content: 'This is very long usual event......' },
        { type: 'error', content: 'This is error event 1.' },
        { type: 'error', content: 'This is error event 2.' },
        { type: 'error', content: 'This is error event 3.' },
        { type: 'error', content: 'This is error event 4.' },
      ];
      break;
    default:
  }
  return listData || [];
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>(); 
  const [user, setUser] = useState<User | null>(null); 
  const [linkedinUsers, setLinkedinUsers] = useState<LinkedInUser[]>([]);  
  const [logMessages, setLogMessages] = useState<Log[]>([]); 
  const [loading, setLoading] = useState<boolean>(true);
  const [graphData, setGraphData] = useState<any[]>([]); 
  const [messages, setMessages] = useState<Message[]>([]); 
  const [newMessage, setNewMessage] = useState<string>(''); 

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      message.error('Kullanıcı ID bulunamadı!');
      return;
    }

    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userResponse = await API.get(`/user/get-user/${id}`);
        setUser(userResponse.data);
        
        const linkedinResponse = userResponse.data.linkedinUsers || [];
        setLinkedinUsers(linkedinResponse);

        const logMessagesResponse = userResponse.data.logMessages || [];
        setLogMessages(logMessagesResponse);

        const allActivities = [...linkedinResponse, ...logMessagesResponse];
        const groupedByDate = groupByDate(allActivities);
        setGraphData(groupedByDate);

      } catch (error) {
        message.error('Kullanıcı bilgileri alınırken bir hata oluştu!');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);  

  const groupByDate = (activities: any[]) => {
    const dateCountMap: { [key: string]: number } = {};

    activities.forEach((activity) => {
      const date = activity.timestamp.split('T')[0];
      dateCountMap[date] = (dateCountMap[date] || 0) + 1;
    });

    const groupedData = Object.keys(dateCountMap).map((date) => ({
      date,
      userCount: dateCountMap[date]
    }));

    return groupedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  const sendMessage = () => {
    if (!newMessage.trim()) return;

    const newChatMessage: Message = {
      sender: user ? user.name : 'Anonymous',
      content: newMessage,
      timestamp: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, newChatMessage]);
    setNewMessage(''); 
  };

  const linkedinColumns = [
    { title: 'Tarih', dataIndex: 'timestamp', key: 'timestamp' },
    { title: 'Bağlantı', dataIndex: 'connect', key: 'connect', render: (connect: boolean) => connect ? 'Bağlantı' : 'Bekleniyor...' },
    { title: 'Takip', dataIndex: 'follow', key: 'follow', render: (follow: boolean) => follow ? 'Takip' : 'Takip Edilmedi.' },
    { title: 'LinkedIn Profili', dataIndex: 'pageUrl', key: 'pageUrl', render: (pageUrl: string) => <a href={pageUrl} target="_blank" rel="noopener noreferrer">Profil</a> },
    { title: 'Kullanıcı Adı', dataIndex: 'linkedinUserName', key: 'linkedinUserName' },
    { title: 'İş Unvanı', dataIndex: 'linkedinUserWork', key: 'linkedinUserWork' },
    { title: 'Konum', dataIndex: 'linkedinUserLocation', key: 'linkedinUserLocation' },
    { title: 'Bağlantı Sayısı', dataIndex: 'linkedinUserConnection', key: 'linkedinUserConnection' },
  ];
  

  const userColumns = [
    { title: 'Tarih', dataIndex: 'timestamp', key: 'timestamp' },
    { title: 'Mesaj', dataIndex: 'logMessage', key: 'logMessage' }
  ];

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'userCount',
    style: {
      fill: 'linear-gradient(-90deg, white 0%, darkgreen 100%)',
    },
    line: {
      style: {
        stroke: 'darkgreen',
        strokeWidth: 2,
      },
    },
    xAxis: {
      label: {
        formatter: (v: string) => v.split('-').slice(1).join('/'), 
      },
    },
  };

  const calendarCellRender = (current: Dayjs, info: any) => {
    if (info.type === 'date') {
      const listData = getListData(current);
      return (
        <ul className="events">
          {listData.map((item) => (
            <li key={item.content}>
              <Badge status={item.type as BadgeProps['status']} text={item.content} />
            </li>
          ))}
        </ul>
      );
    }
    return info.originNode;
  };
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2}>{user ? `${user.name} Kullanıcısı Sayfası` : 'Kullanıcı Bilgileri Yükleniyor...'}</Title>
      <Button 
        color = "danger"
        variant="solid"
        style={{ marginBottom: '20px' }} 
        onClick={handleLogout}
      >
        Çıkış Yap
      </Button>
      {loading ? (
        <Spin size="large" tip="Yükleniyor..." />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Anasayfa" key="1">
            <Row gutter={16}>
              <Col span={12}>
                <Card title="LinkedIn Logları" bordered={false}>
                  <Table
                    columns={linkedinColumns}
                    dataSource={linkedinUsers}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                  />
                </Card>
              </Col>

              <Col span={12}>
                <Card title="Kullanıcı Logları" bordered={false}>
                  <Table
                    columns={userColumns}
                    dataSource={logMessages}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Card title="Aktivite Grafiği" bordered={false} style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <Area {...config} />
                </Card>
              </Col>

              <Col span={12}>
                <Card title="Chat" bordered={false}>
                  <div style={{ maxHeight: 300, overflowY: 'auto', marginBottom: '10px' }}>
                    {messages.map((msg, index) => (
                      <div key={index}>
                        <strong>{msg.sender}:</strong> {msg.content} <br />
                        <small>{new Date(msg.timestamp).toLocaleString()}</small>
                        <hr />
                      </div>
                    ))}
                  </div>
                  <TextArea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    rows={4}
                    placeholder="Yeni mesaj yazın..."
                  />
                  <Button
                    color = "default"
                    variant="solid"
                    onClick={sendMessage}
                    style={{ marginTop: '10px' }}
                  >
                    Gönder
                  </Button>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Takvim" key="2">
            <Card title="Takvim" bordered={false}>
              <Calendar cellRender={calendarCellRender} />
            </Card>
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default App;
