import React, { useState } from 'react';
import { Form, Input, Button, message, Typography } from 'antd';
import API from '../api';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; 

const { Title } = Typography;

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true);

    try {
      const authResponse = await API.post('/user/authenticate', values);

      if (authResponse.status === 200) {
        const { role, userId } = authResponse.data;

        if (role === 'admin') {
          message.success('Admin olarak giriş başarılı!');
          localStorage.setItem('authToken', 'your-admin-token');
          navigate('/'); 
        } else if (role === 'user') {
          message.success('Kullanıcı olarak giriş başarılı!');
          localStorage.setItem('authToken', 'your-user-token');
          localStorage.setItem('userId', userId);
          navigate(`/userPage/${userId}`);
        }
      }
    } catch (error) {
      message.error('Giriş yapılırken bir hata oluştu!');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div style={{ maxWidth: '400px', margin: 'auto', padding: '50px 0' }}>
      <div className="welcome-message">
        <Title level={1}>Klarline Hoş Geldiniz!</Title>
      </div>
      <Form name="login-form" onFinish={onFinish} layout="vertical">
        <Form.Item 
          label="Email" 
          name="email" 
          rules={[{ required: true, message: 'Lütfen e-posta adresinizi girin!' }]}>
          <Input placeholder="E-posta adresi" />
        </Form.Item>

        <Form.Item 
          label="Şifre" 
          name="password" 
          rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}>
          <Input.Password placeholder="Şifre" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Giriş Yap
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
