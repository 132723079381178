import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../api';
import { Table, Card, Typography, Spin } from 'antd';

interface User {
  id: number;
  name: string;
  email: string;
}

interface Log {
  id: number;
  logMessage: string;
}

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    API.get(`/user/get-users`)
      .then((response) => {
        const selectedUser = response.data.find((u: User) => u.id === Number(id));
        setUser(selectedUser);
      })
      .catch(console.error);

    API.get(`/log/list/${id}`)
      .then((response) => {
        setLogs(response.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }} />;
  }

  return (
    <div style={{ padding: '20px' }}>
      {user && (
        <Card title={`${user.name}'in Detayları`} bordered={false} style={{ marginBottom: '20px' }}>
          <Typography.Text>Email: {user.email}</Typography.Text>
        </Card>
      )}

      <Typography.Title level={4}>Loglar</Typography.Title>
      <Table
        dataSource={logs}
        columns={[{ title: 'Log Mesajı', dataIndex: 'logMessage', key: 'logMessage' }]}
        rowKey="id"
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default UserDetails;
